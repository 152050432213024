import React, { useState } from 'react';
import 'twin.macro';
import Wolf from '../../../../src/images/WolfeLogo.png';
import Typewriter from 'typewriter-effect';
import Img from "gatsby-image"
import { useStaticQuery, graphql } from 'gatsby';



export default function HeroPw() {
  const data = useStaticQuery(graphql`
  query {
    file(relativePath: { eq: "WolfeLogo.png" }) {
      childImageSharp {
        fluid {
          base64
          aspectRatio
          src
          srcSet
          sizes
        }
      }
    }
  }
  `)
  const [showSecondHeadline, SetshowSecondHeadline] = useState(false);
console.log('query data', data)
  return (
    <div tw="bg-gray-50">
      <div tw="relative overflow-hidden">
        <div tw="absolute inset-y-0 h-full w-full" aria-hidden="true"></div>

        <div tw="relative pt-6 pb-4 sm:pb-8">
          <div tw="mt-1 mx-auto max-w-7xl px-4 sm:mt-4 sm:px-6">
            <div tw="text-center">
              <h1 tw="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl ">
                <span tw="block">Planet Wolfe</span>
                <div tw="flex justify-center flex-wrap text-3xl lg:text-6xl lg:min-h-24" >
                <Typewriter
                options={{
                    cursor:"",
                    cursorClassName:""
                }}
                  onInit={(typewriter) => {
                    typewriter
                      .typeString(
                        `<span style="color: #01B8FC;">In this house, we build</span>`
                      )
                      .callFunction(() => {
                        SetshowSecondHeadline(true);
                      }) 
                      .start();
                  }}
                />
                {showSecondHeadline && (
                  <Typewriter
                    options={{
                      autoStart: true,
                      cursor: null,
                      delay: 75,
                      loop: true,
                      strings: [`<span style="color: #000; padding-left: 15px; font-weight: bold;">Success</span>`, '<span style="color: #000; padding-left: 15px;">Brands</span>', '<span style="color: #000; padding-left: 15px;">Pagespeed</span>', '<span style="color: #000; padding-left: 15px;">Creative Services</span>', '<span style="color: #000; padding-left: 15px;">analytics</span>'],
                    }}
                  />
                )}
                </div>
              </h1>
              <p tw="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                Thank you to our loyal clients giving us over 20 years in the
                business. Together we grow, change, reinvent, and push business
                to new levels.
              </p>
            </div>
          </div>
        </div>
        <div tw="relative">
          <div tw="absolute inset-0 flex flex-col" aria-hidden="true">
            <div tw="flex-1"></div>
            <div tw="flex-1 w-full bg-gray-500"></div>
          </div>
          <div tw="w-full flex justify-center items-center mx-auto px-4 sm:px-6 max-w-4xl">
           
              <Img height="848" width="848" tw="relative w-full" fluid={data.file.childImageSharp.fluid} alt="A corgi smiling happily" loading="eager"  />

          </div>
        </div>
      </div>
      <div tw="bg-gray-800"></div>
    </div>
  );
}
